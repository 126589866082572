import React from 'react';


interface IProps {
    onClick : (event: React.MouseEvent<HTMLElement>) => void;
    isOn ?: boolean;
}

export const BtnAudio = ({onClick, isOn}: IProps) => 
    <div className="btn" id="controls-audio" onClick={onClick}>
        <div className='circle'>
            <img src="icons/audio.svg" alt="" />
        </div>
        <div className="desc">{isOn ? 'mute' : 'switch on'}</div>
    </div>

export const BtnVideo = ({onClick, isOn}: IProps) => 
    <div className="btn" id="controls-audio" onClick={onClick}>
        <div className='circle'>
            <img src="icons/video.svg" alt=""/>
        </div>
        <div className="desc">{isOn ? 'hide video' : 'show video'}</div>
    </div>

export const BtnFullScreen = ({onClick, isOn}: IProps) => 
    <div className="btn" id="controls-fullscreen" onClick={onClick}>
        <div className='circle'>
            <img src="icons/full-screen.svg" alt=""/>
        </div>
        <div className="desc">{isOn ? 'exit full screen' : 'go fullscreen'}</div>
    </div>

           