import React, {useState, useEffect} from 'react';
import {BtnAudio, BtnVideo, BtnFullScreen} from '../components/button';

interface IProps {
    onBtnAudioClick: () => void,
    onBtnVideoClick: () => void,
    isAudioOn: boolean,
    isVideoOn: boolean,
}


const ControlBar = ({onBtnAudioClick, onBtnVideoClick, isAudioOn, isVideoOn} : IProps) => {

    const [isFullScreenOn, setIsFullScreen] = useState(false);
    const [isFullScreenSupported, setIsFullScreenSupported] = useState(false);

    useEffect(
        () => {
            document.addEventListener('fullscreenchange', () => {
                const isFull = !!document.fullscreenElement;
                setIsFullScreen(isFull);
            });
            if (document.fullscreenElement === null) { // otherwise would return undefinied
                setIsFullScreenSupported(true);
            };
        },
        [],
    )
    

    const onBtnFullScreenClick = () => {
        if (isFullScreenOn) {
            // @ts-ignore
            document.webkitExitFullscreen();
            setIsFullScreen(false);
        } else {
            document.documentElement.requestFullscreen();
            setIsFullScreen(true);
        }
    }


    return(
        <div id="controls">
        <BtnAudio onClick={onBtnAudioClick} isOn={isAudioOn}/>
        <BtnVideo onClick={onBtnVideoClick} isOn={isVideoOn}/>
        {isFullScreenSupported &&
            <BtnFullScreen onClick={onBtnFullScreenClick} isOn={isFullScreenOn}/>
        }
    </div>
    )
}

export default ControlBar;

