const fetchNewRoomId = (): Promise<string> => {
    return new Promise<string>(async (resolve, reject) => {
        try {
            const id = Math.random().toString().substr(2,7);
            resolve(id);    
        } catch (error) {
            reject(error);
        }        
    })
}

const isRoomIdValid = (room_id: string): Promise<string> => {
    return new Promise<string>(async (resolve, reject) => {

        return true;
        /*
        try {
            
        } catch (error) {
            reject(error);
        }  
        */      
    })
}

export { fetchNewRoomId, isRoomIdValid };
