// react and streams:
// https://stackoverflow.com/questions/48603402/how-to-set-srcobject-on-audio-element-with-react/48603493#48603493

import React from 'react';

interface IVideoProps {
    isFocused: boolean,
    orientation: 'portrait' | 'landscape',
    userName: string,
    onTap: ()=>void,
}

const Video = React.forwardRef((
    { orientation, userName, isFocused, onTap} : IVideoProps,
    ref : any
    ) =>
    <div 
        className={'video-wrapper user userVideo '+ (isFocused ? 'focused' : '')}
        onClick={isFocused ? ()=> {}: onTap}
    >
        <video 
            ref={ref}
            className={orientation}
            autoPlay
            playsInline
            controls={false}
        ></video>
           
        <span className="userName">{userName}</span>
    </div>
)

export { Video } ;

