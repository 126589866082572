import React from 'react';

interface IHomeViewProps {
    onStaterMeetingClicked: ()=> void
}

const HomeView = ({ onStaterMeetingClicked } : IHomeViewProps) => 
    <div className={'center-view'}>
        <button onClick={onStaterMeetingClicked} style={{padding:'10px'}}>New meeting</button>
    </div>

export default HomeView;
