import React, { useState } from 'react';


interface IJoinPopupProps {
    onJoinWithAudio: (userName:string) => void,
    onJoinWithVideo: (userName:string) => void,
}

const JoinPopup = ({onJoinWithAudio, onJoinWithVideo}: IJoinPopupProps) => {

    const [userName, setUserName] = useState('');

    const disabled = !(userName.length);

    return(
        <div style={styles.wrapper}>
            <div style={styles.popup}>
                <input 
                    style={styles.input}
                    type="text"
                    placeholder="Type your name"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                />
                <br/>
                <div style={styles.btn_wrapper}>
                    <button style={styles.btn} onClick={()=>onJoinWithVideo(userName)} disabled={disabled}>Join with video</button>
                    <button style={styles.btn} onClick={()=>onJoinWithAudio(userName)} disabled={disabled}>Join with audio</button>
                </div>
            </div>
        </div>
    )
}
    

export default JoinPopup

const styles= {
    wrapper: {
        position: 'absolute' as 'absolute',
        top:0,
        left: 0,
        height:'100vh',
        width:'100vw',
        backgroundColor:'rgba(255,255,255,0.3)',
        zIndex:1000,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
    },
    popup: {
        backgroundColor:'white',
        padding:'50px',
    },
    input: {
        fontSize:'20px',
        padding:'0.5em',
        width:'300px',
        marginBottom:'50px',
    },
    btn_wrapper: {
        width:'100%',
        display:'flex',
        justifyContent:'space-evenly',
    },

    btn: {
        fontSize:'14px',
        padding:'0.5em',
    }
}