const volumeDetector = function(stream: MediaStream, callback: any) {
  
  let repeats = 0; // agains cracking noises

  var streamData = new Uint8Array(128);
  
  const Ac = new window.AudioContext();

  const Analyzer = Ac.createAnalyser();
  Analyzer.fftSize = 256;
  //Analyzer.smoothingTimeConstant = 0.2; // 

  const StreamSource = Ac.createMediaStreamSource(stream);
  StreamSource.connect(Analyzer);

  let history:Array<number> = [];

  function throttle(callback: any, interval: number) {
    let enableCall = true;
    // @ts-ignore
    return function(...args) {
      if (!enableCall) return;
      enableCall = false;
      // @ts-ignore
      callback.apply(this, args);
      setTimeout(() => enableCall = true, interval);
    }
}

const callbackThrottled = throttle(callback, 1000);



  setInterval(() => {

      Analyzer.getByteFrequencyData(streamData);
      var total = 0;

      for (var i = 0; i < streamData.length; i++) {
        total = total + streamData[i];
      }
        
      const volume =  total/1000;
      
  if (volume > 2) {

    let count = history.length;
    const everage = count ? history.reduce((previous, current) => current += previous) / history.length : null;
    if (!everage || (everage && volume > everage + 2)) {
      repeats ++;
      if (repeats < 3) return; 
      callbackThrottled(volume)
    }
  } else {
    repeats = 0;
  }
  history = [volume, ...history.slice(0,9)];

      
}, 200);

}

export default volumeDetector;