import React from 'react';
import socketIOClient from "socket.io-client";

import ControlBar from './ControlBar';
import {Video} from '../components/Video';
import getLocalStream from '../utils/getLocalStream';
import JoinPopup from './JoinPopup';
import volumeDetector from '../utils/volumeDetector'

// localStorage.debug = 'socket.io-client:socket'

const AUTOCONNECT = false;
//const BACKEND_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '';
const BACKEND_URL = 'https://chat.neptun.dev';

interface IProps {
    room_id: string;
}

interface IState {
    amIJoinedRoom: boolean,
    isAudioOn: boolean,
    isVideoOn: boolean,
    whoIsSpeakingNow: string | null, //socketId
    myUserName: string | null,
    peers: Array<
        {
            socketId: string,
            username: string,
            videoRatio?: number | null,
        }
    >
}

interface myNameIsMessage {
    username: string
}


class SpeakerView extends React.Component<IProps, IState> {

    private localVideoRef: HTMLVideoElement | null = null;
    private peerVideosRef: any = {};
    private callees: Array<
        {
            socketId: string,
            username: string,
            peerConn?: RTCPeerConnection,
            stream?: MediaStream, 
        }> = [];

    private socketHandler: SocketIOClient.Socket | null = null;
    private localStream: MediaStream | null = null;
    private mySocketId: string | null = null;

    public state = {
        amIJoinedRoom: false,
        isAudioOn: true,
        isVideoOn: true,
        myUserName: null,
        whoIsSpeakingNow: null, // connectionId
        peers: [] as Array<
        {
            socketId: string,
            username: string,
            videoRatio: null;
        }>
    }


    private sendToUserMessage = (
        method: 'ICEcandidateMessage' | 'sessionDescriptionMessage' | 'myNameIsMessage',
        message:any,
        socketId:string
    ) => {
        const connection = this.socketHandler;
        if (!connection) return;
        connection.emit((method as unknown) as string, {message, socketId});
      }

    private async buildPeer(socketId: string) {
        
        // get ice servers
        const r = await fetch(BACKEND_URL + '/turn', { method: 'POST' });
        const { iceServers } = await r.json(); 

        const rtcConfig = {iceServers};

        const peerConn = new RTCPeerConnection(rtcConfig);

        const localStream = this.localStream;
        if (!localStream) {
            console.log('no localStrem in buildPeer fn');
            return null;
        }
        
        localStream.getTracks().forEach(track => {
            peerConn.addTrack(track, localStream);
        });

        peerConn.ontrack = (event: RTCTrackEvent) => {
            
            const stream = event.streams[0];
            const peerItem = this.callees.find(el => el.socketId === socketId)
            if (!peerItem) return;
            peerItem.stream = stream;

            const tracks = stream.getTracks();
            if (!tracks[0]) return;

            this.forceUpdate();            
        }
        
        peerConn.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
            if (event.candidate) {
                this.sendToUserMessage(
                    'ICEcandidateMessage',
                    {
                        label: event.candidate.sdpMLineIndex,
                        id: event.candidate.sdpMid,
                        candidate: event.candidate.candidate
                    },
                    socketId
                );
            }
        }
        peerConn.oniceconnectionstatechange = (event) => {

        };


        const peerItem = this.callees.find(el => el.socketId === socketId)
        if (peerItem) {
            peerItem.peerConn = peerConn;
        }

        return peerItem;
    }

    private runSocketListeners = () => {

        const { socketHandler } = this;
        if (!socketHandler) return;

        const context = this;

        socketHandler.on('meCreatedRoom', () => {
            // console.log('do nothing for now in meCreatedRoom');
            // console.log('MY SOCKET ID', context.socketHandler.io.engine.id);
        });

        socketHandler.on('meJoinedRoom', () => {
            // console.log('do nothing for now in meJoinedRoom');
        });

        socketHandler.on('someoneJoinedRoom', async ({socketId, username}: {socketId:string, username:string}) => {
            this.callees.push({socketId, username});
            this.sendToUserMessage('myNameIsMessage', {username: context.state.myUserName}, socketId);
        });

        socketHandler.on('myNameIsMessage', async ( // send username to just joined user
            {message, socketId}: {message: myNameIsMessage, socketId: string}
        ) => {
            const { username } = message;
            // not sure why duplicates apperas - temporary fixes
            const isAlreadyOnList = !!this.callees.filter(p => p.socketId === socketId).length;
            if (isAlreadyOnList) return;
            this.callees.push({socketId, username});
            const peerItem = await this.buildPeer(socketId);
            if (!peerItem || !peerItem.peerConn) return;
            const { peerConn } = peerItem;
            const sessionDescription = await peerConn.createOffer();
            peerConn.setLocalDescription(sessionDescription);
            this.sendToUserMessage( 'sessionDescriptionMessage', sessionDescription, socketId);
        });

        socketHandler.on('sessionDescriptionMessage', async (
            {message, socketId}: {message: RTCSessionDescription, socketId: string}) =>  {
            let peerItem = context.callees.find(el => el.socketId === socketId);
            if (message.type === 'offer') {
                const peerItem = await this.buildPeer(socketId);
                if (!peerItem || !peerItem.peerConn) {
                    return;
                }
                const { peerConn } = peerItem;
                peerConn.setRemoteDescription(new RTCSessionDescription(message));                
                const sessionDescription = await peerConn.createAnswer();
                peerConn.setLocalDescription(sessionDescription);
                context.sendToUserMessage('sessionDescriptionMessage', sessionDescription, socketId);
            }

            else if (message.type === 'answer') {
                if (!peerItem || !peerItem.peerConn) return;
                peerItem.peerConn.setRemoteDescription(new RTCSessionDescription(message));
            }

            this.addPeerToState(socketId);
        });

        socketHandler.on('ICEcandidateMessage', ({message, socketId}: {message: any, socketId: string}) =>  {
            let peerItem = context.callees.find(el => el.socketId === socketId);
            if (!peerItem || !peerItem.peerConn) return;
                var candidate = new RTCIceCandidate({
                  sdpMLineIndex: message.label,
                  candidate: message.candidate
                });
                peerItem.peerConn.addIceCandidate(candidate);
        });

        socketHandler.on('speakingNow', ({socketId} : {socketId: string}) => {
            console.log('speaking now:', socketId);
            this.setState({whoIsSpeakingNow: socketId})
        });

        socketHandler.on('userDisconected', ({socketId} : {socketId: string}) => {
            this.handleOtherDisconnected(socketId);
        });
    };

    private handleOtherDisconnected = (socketId: string) => {
        this.callees = this.callees.filter(p => p.socketId !== socketId);
        const whoIsSpeakingNow = this.state.whoIsSpeakingNow === socketId ? 'me' : this.state.whoIsSpeakingNow;
        const peers = this.state.peers.filter(p => p.socketId !== socketId);
        this.setState({
            whoIsSpeakingNow,
            peers
        })
    }

    private addPeerToState = (socketId: string) => {
        const peer = this.callees.find(p => p.socketId === socketId);
        if (!peer) return;
        this.setState(
            {peers: [
                ...this.state.peers, 
                {
                    username:peer?.username,
                    socketId,
                }  
            ]}
        );
    }

    private initializeSocketAndJoin = async (username: string) => {
        const { room_id } = this.props;
        const context = this;
        this.socketHandler = socketIOClient(BACKEND_URL);

        this.socketHandler.on('disconnect', (reason: string) => {
         console.log('disconnect', reason);
        });

        this.socketHandler.on('connect', function () {
            //@ts-ignore
            const socketId = context.socketHandler.id;
            if (socketId === context.mySocketId) {
                return;
            }
            if (!context.socketHandler) return; // ts
            if (!context.mySocketId) { // first connect
                context.runSocketListeners();
            } else { // reconnect after connection lost
                context.setState({peers:[]})
                context.callees = [];
            }
            context.mySocketId = socketId;
            context.socketHandler.emit('createOrJoin', room_id, username);
        });
        this.setState({amIJoinedRoom: true});

    }

    private onBtnAudioClick = async () => {
        if (!this.localStream) return;
        this.localStream.getAudioTracks().forEach(track => {
            track.enabled = !track.enabled;
            this.setState({isAudioOn: track.enabled});
        });
    }


    private onBtnVideoClick = async () => {
        if (!this.localStream) return;
        this.localStream.getVideoTracks().forEach(track => {
            track.enabled = !track.enabled;
            this.setState({isVideoOn: track.enabled});
        });
    }

    private requestJoinWithAudio = (username:string) => {
        if (!this.localStream) return;
        this.localStream.getVideoTracks().forEach(track => {
            track.enabled = !track.enabled;
        });
        this.setState(
            {
                myUserName: username,
                isVideoOn: false,
            },
            () => this.initializeSocketAndJoin(username),
        )
    }

    private requestJoinWithVideo = (username:string) => {
        this.setState(
            {
                myUserName: username,
            },
            () => this.initializeSocketAndJoin(username),
        )
    }

    private handleMeSpeaking = () => {
        if (this.state.whoIsSpeakingNow === 'me') return;
        console.log('me sepaking now');
        this.setState(
            {whoIsSpeakingNow: 'me'},
            () => {
                if (!this.socketHandler) return;
                this.socketHandler.emit('meSpeakingNow');
            }
        )
    }

    private setLocalStream = async () => {
        try {
            const { isAudioOn, isVideoOn } = this.state;
            this.localStream = await getLocalStream({withAudio: isAudioOn, withVideo:isVideoOn});
            if (this.localVideoRef) {
                this.localVideoRef.srcObject = this.localStream;
                this.localVideoRef.muted=true;
            }
            if (AUTOCONNECT) {
                this.requestJoinWithVideo('seb-' + Math.random().toString());
            }

            volumeDetector(
                this.localStream, (val:number) => {
                this.handleMeSpeaking();
            });

        } catch (error) {
            return error;
        }
    }

    public componentDidMount = async () => {
       await this.setLocalStream();
    }

    public componentDidUpdate = (prevProps: IProps, prevState: IState) => {
        this.state.peers.forEach(({socketId}) => {
            const peerItem = this.callees.find(el => el.socketId === socketId);
            if (peerItem && peerItem.stream && this.peerVideosRef[socketId]) {
                this.peerVideosRef[socketId].srcObject = peerItem.stream;
            }
        })
    }



    public render = () => {

        const { amIJoinedRoom, peers, isVideoOn, isAudioOn, whoIsSpeakingNow } = this.state;

        const speakingNow = whoIsSpeakingNow ? whoIsSpeakingNow : 'me';

        

        // console.log('this.peerVideosRef', this.peerVideosRef);
        // console.log('this.callees', this.callees);

        return(
            <div id='wrapper' className='speakerView'>
            

            <div className="videoList">

                <Video
                    key='me'
                    ref={(video: HTMLVideoElement) => {this.localVideoRef = video}}
                    isFocused={speakingNow === 'me'}
                    orientation="landscape"
                    userName={this.state.myUserName || ''}
                    onTap = {()=>this.setState({whoIsSpeakingNow:'me'})}
                />

                {peers.map(({socketId, username}) => (
                    <Video
                        key={socketId}
                        ref={(video: HTMLVideoElement) => {this.peerVideosRef[socketId] = video}}
                        isFocused={speakingNow === socketId}
                        orientation="landscape"
                        userName={username}
                        onTap = {()=>this.setState({whoIsSpeakingNow:socketId})}
                    />
                ))}
                
            </div>

            <ControlBar 
                onBtnAudioClick={this.onBtnAudioClick}
                onBtnVideoClick={this.onBtnVideoClick}
                isVideoOn={isVideoOn}
                isAudioOn={isAudioOn}
            />

        {
        !amIJoinedRoom && <JoinPopup
            onJoinWithAudio={this.requestJoinWithAudio}
            onJoinWithVideo={this.requestJoinWithVideo}
        />
        }


        </div>
        );
    }

}



export default SpeakerView;
