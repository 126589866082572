interface IParams {
    withVideo: boolean,
    withAudio: boolean,
}

const getMediaStream = ({withVideo, withAudio}: IParams): Promise<MediaStream> => {
    return new Promise<MediaStream>(async (resolve, reject) => {
        /*
        const constraints = {
            'video': withVideo, 
            'audio': withAudio ? {
                echoCancellation: true
               } : false
            };
        */
       const constraints = {
        'audio': {
            echoCancellation: true
           },
        "video": true,
       }
        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            resolve(stream);    
        } catch (error) {
            reject(error);
        }
        
    })
}

const getMediaStreamDefault = async(): Promise<MediaStream> => {
    return await getMediaStream({withAudio:true, withVideo:true});
}

export {getMediaStreamDefault};  

export default getMediaStream;