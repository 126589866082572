import React, { useState, useEffect } from 'react';
import './App.css';
import SpeakerView from './screen/SpeakerView';
import HomeView from './screen/HomeView';
import { getMediaStreamDefault } from './utils/getLocalStream';
import { fetchNewRoomId, isRoomIdValid } from './utils/roomIdHelpers';

/*
const goToRoom = (room_id: string) => {
  const {protocol, host} =  document.location;
  const callUrl = protocol + '//' + host + '/#room/' + room_id;
  document.location.href = callUrl;
}

const goHome = () => {
  const {protocol, host} =  document.location;
  const callUrl = protocol + '//' + host;
  document.location.href = callUrl;

}
*/

function App() {

  const [room_id, setRoomId] = useState<string | null>(null);
  
  useEffect(() => {
    
    const room_id = document.location.hash.replace('#room/', '');
    if (room_id && isRoomIdValid(room_id)) {
      setRoomId(room_id);
    }

    window.addEventListener('hashchange',()=>{
      const room_id = document.location.hash.replace('#room/', '');
      const roomIdParsed= room_id && isRoomIdValid(room_id) ? room_id : null;
      setRoomId(roomIdParsed);
    })
  }, [])

  const onStartedMeetingClicked = async () => {
    await getMediaStreamDefault();
    const room_id = await fetchNewRoomId();
    const {protocol, host} =  document.location;
    const callUrl = protocol + '//' + host + '/#room/' + room_id;
    document.location.href = callUrl;
    setRoomId(room_id)
  }

  return room_id ? <SpeakerView room_id={room_id}/> : <HomeView onStaterMeetingClicked={onStartedMeetingClicked}/>

}

export default App;
